.wrapper {
  flex-basis: 100%;
  display: flex;
  margin: 0 auto;
}
.content-wrapper {
  position: relative;
  flex-grow: 1;
}
.inner-wrapper {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  justify-content: space-between;
}
.content {
  flex-grow: 1;
  flex-basis: 100%;
  position: absolute;
  width: 100%;
  max-height: 100%;
}
.overflow-auto-y {
  overflow-y: auto;
}

.filterMessages__menu-list {
  z-index: 999 !important;
}
.proctor-groupstyles {
  display: flex;
  align-items: 'center';
  justify-content: 'space-between';
}

.printTable {
  page-break-inside: 'avoid';
  border-collapse: separate;
  > tbody > tr > td {
    border: 1px solid gray;
    border-radius: 0.5em;
    padding: 0.5em;
  }
  table.pinInfo {
    tr.nuid {
      td:first-child { padding-right: 2em;}
      font-size: 80%;
    }
  }
}